import { useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import { GAEvent } from '../../types'
import SubSectionLayout from '../SubSectionLayout/SubSectionLayout'
import './SpotlightSection.scss'

export interface IAnnouncement {
  title: string
  description: string
  image: string
  image_right_aligned: boolean
  alt?: string
  links: {
    link_text: string
    link: string
    gaEvent?: GAEvent
  }[]
  colours?: {
    border?: string
    background?: string
    title?: string
    description?: string
    link: string
  }
}

export const SPOTLIGHT_SECTION_TEST_ID = 'spotlight-section'
export const SPOTLIGHT_SECTION_SLIDE_TEST_ID = 'spotlight-section-slide'
export const SPOTLIGHT_SECTION_DOT_TEST_ID = 'spotlight-section-dot'
export const SPOTLIGHT_SECTION_CONTROL_PREV_TEST_ID =
  'spotlight-section-control-prev'
export const SPOTLIGHT_SECTION_CONTROL_NEXT_TEST_ID =
  'spotlight-section-control-next'

const SpotlightSection = ({
  announcements,
}: {
  announcements: IAnnouncement[]
}) => {
  /* No Slides */
  if (!announcements.length) return null

  /* Multiple Slides */
  if (announcements.length > 1) {
    const sliderRef = useRef<Slider>(null)
    const [currentSlide, setCurrentSlide] = useState(0)

    const settings: Settings = {
      adaptiveHeight: true,
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (_oldIndex, newIndex) => setCurrentSlide(newIndex),
      accessibility: true,
    }

    return (
      <section
        className="SpotlightSection"
        data-testid={SPOTLIGHT_SECTION_TEST_ID}
      >
        <Slider ref={sliderRef} {...settings}>
          {announcements.map((announcement, index) => (
            <AnnouncementSlide
              key={`announcement-${index}`}
              announcement={announcement}
            />
          ))}
        </Slider>

        <div className="SpotlightSection__controls">
          <button
            aria-label="previous slide"
            className="SpotlightSection__control"
            onClick={() => sliderRef.current?.slickPrev()}
            data-testid={SPOTLIGHT_SECTION_CONTROL_PREV_TEST_ID}
          >
            <i className="bx bx-chevron-left" />
          </button>
          <div className="SpotlightSection__dots">
            {announcements.map((_, index) => (
              <button
                key={`dot-${index}`}
                aria-label={`go to slide ${index}`}
                onClick={() => sliderRef.current?.slickGoTo(index)}
                className={`SpotlightSection__dot ${
                  currentSlide === index ? 'active' : ''
                }`}
                data-testid={SPOTLIGHT_SECTION_DOT_TEST_ID}
              >
                <i className="bx bxs-circle text-[8px]"></i>
              </button>
            ))}
          </div>
          <button
            aria-label="next slide"
            className="SpotlightSection__control"
            onClick={() => sliderRef.current?.slickNext()}
            data-testid={`${SPOTLIGHT_SECTION_CONTROL_NEXT_TEST_ID}`}
          >
            <i className="bx bx-chevron-right" />
          </button>
        </div>
      </section>
    )
  }

  /* Single Slide */
  return (
    <section
      className="SpotlightSection"
      data-testid={SPOTLIGHT_SECTION_TEST_ID}
    >
      <AnnouncementSlide announcement={announcements[0]} />
    </section>
  )
}

/* Individual Slide */
const AnnouncementSlide = ({
  announcement,
}: {
  announcement: IAnnouncement
}) => (
  <div
    key={`${announcement.title}`}
    style={{
      backgroundColor: announcement.colours?.background,
      borderColor: announcement.colours?.border,
    }}
    className="SpotlightSection__container SpotlightSectionSlide"
    data-testid={SPOTLIGHT_SECTION_SLIDE_TEST_ID}
  >
    <SubSectionLayout {...announcement} />
  </div>
)

export default SpotlightSection
