import { Button } from 'component-library'
import { GAEvent, sendCustomGAEvent, slugifyString } from 'shared-utilities'
import LinkComponent from '../LinkComponent/LinkComponent'
import Image from '../Image/Image'
import PermaLink from '../PermaLink/PermaLink'
import MarkdownText from '../MarkdownText/MarkdownText'
import './style.scss'

interface SectionLayoutProps {
  title: string
  id?: string
  description: string
  image: string
  image_right_aligned?: boolean
  alt?: string
  links?: {
    link_text: string
    link: string
    gaEvent?: GAEvent
  }[]
  button_text?: string
  button_link?: string
  button_onClick?: () => void
  colours?: {
    title?: string
    description?: string
    link?: string
  }
}
// needs to be included in a wrapper
const SubSectionLayout = ({
  title,
  id = '',
  description,
  image,
  image_right_aligned,
  alt = '',
  links,
  button_text,
  button_link,
  button_onClick,
  colours,
}: SectionLayoutProps) => {
  const imageSrc = image.startsWith('https://') ? image : `/images/${image}`

  return (
    <div
      className={`SectionLayout__wrapper ${
        image_right_aligned ? 'flex-row-reverse' : 'flex-row'
      }`}
      data-testid="sub-section-layout"
      id={id}
    >
      <div className="SectionLayout__image">
        <Image
          src={imageSrc}
          alt={alt}
          data-testid="sub-section-layout-image"
        />
      </div>

      <div
        id={`${slugifyString(title.replaceAll('.', ''))}`}
        className={`SectionLayout__content scroll-mt-36 ${
          image_right_aligned
            ? 'SectionLayout__content--rightMargin'
            : 'SectionLayout__content--leftMargin'
        }`}
      >
        <h2 style={{ color: colours?.title }} className="SectionLayout__title">
          {title}
          <PermaLink title={title} />
        </h2>

        <MarkdownText
          style={{ color: colours?.description }}
          text={description}
          className="SectionLayout__description"
        />

        {button_text &&
          (button_link ? (
            <LinkComponent
              href={button_link}
              className="SectionLayoutButton__link"
            >
              <Button
                style={{ color: colours?.link }}
                label={button_text}
                className="SectionLayout__button"
                onClick={button_onClick}
              />
            </LinkComponent>
          ) : (
            <Button
              style={{ color: colours?.link }}
              label={button_text}
              className="SectionLayout__button"
              onClick={button_onClick}
            />
          ))}

        {links && (
          <>
            {links.map((link, index) => {
              return (
                <LinkComponent
                  href={link.link}
                  className="SectionLayout__link"
                  key={`${link.link}-${index}`}
                  style={colours?.link ? { color: colours?.link } : {}}
                  onClick={() => {
                    if (link.gaEvent) {
                      sendCustomGAEvent('click', link?.gaEvent)
                    }
                  }}
                >
                  <span>{link.link_text}</span>{' '}
                  <i className="bx bx-chevron-right"></i>
                </LinkComponent>
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default SubSectionLayout
